<template>
  <div class="container-fluid">
    <div class="row">

      <sidebar></sidebar>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <div class="row">
          <div class="col-12 mb-5">
            <app-header></app-header>
            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
          </div>
        </div>
        <!-- Presentation section -->
        <section class="presentation-section  px-0 mx-0 px-xl-5 mx-xl-5  mt-0 mt-md-5 mb-3">
          <div class="container-fluid ">
            <div class="row align-items-center h-100">

              <div class="col-12">
                <div class="bg-light b-30 p-4 mb-4">
                  <sk-list v-if="loading" :height="0.03" :items-count="6"></sk-list>
                  <div v-if="!loading && books.length === 0" class="text-center">
                    {{ $t('online-books.books-not-found') }}
                  </div>
                  <vue-good-table
                    class="table-blue-wide w-100"
                    v-if="!loading && books.length > 0"
                    :columns="columns"
                    :rows="books"
                    :paginationOptions="paginationOptions"
                    :search-options="{
                      enabled: true,
                      trigger: 'enter'
                    }">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'link' || props.column.field == 'mime'">
                        <a    href="#" role="button" @click="downloadFile(props.row.link,props.row.mime)">{{ props.row.link }}</a>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                  <div v-else class="text-center">Please Wait, LOADING...</div>
                  <!--    <table v-if="!loading && books.length > 0" class="table-blue-wide w-100">-->
                  <!--      <thead>-->
                  <!--      <tr>-->
                  <!--        <th>{{$t("online-books.name")}}</th>-->
                  <!--        <th>{{$t("online-books.link")}}</th>-->
                  <!--      </tr>-->
                  <!--      </thead>-->
                  <!--      <tbody>-->
                  <!--      <tr class="cursor-pointer" v-for="title in books">-->
                  <!--        <td :data-label="$t('online-books.name')">{{ title }}</td>-->
                  <!--        <td :data-label="$t('online-books.link')">{{ books[title] }}</td>-->
                  <!--      </tr>-->
                  <!--      </tbody>-->
                  <!--    </table>-->
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  </div>

</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';

import SkList from '../components/skeletons/sk-list';
import BookResource from '../api/bookResource';
import { VueGoodTable } from 'vue-good-table';

const resource = new BookResource()

export default {
  name: 'online-books',
  components: { SkList, VueGoodTable, sidebar, appHeader, pageHeader },
  data() {
    return {
      pageData: {
        title: this.$t('online-books.title'),
        breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
          {
            icon: '',
            title: this.$t('online-books.title'),
            link: '/online-books',
            isActive: true,
          }]
      },
      columns: [{
        label: this.$t('online-books.name'),
        field: 'name',
      }, {
        label: this.$t('online-books.link'),
        field: 'link',
      }],
      books: [],
      loading: false,
      paginationOptions: {
        enabled: true,
        perPageDropdownEnabled: true,
        mode: 'pages',
        perPage: 50,
        perPageDropdown: [10, 25, 50, 75, 100]
      }
    }
  },

  mounted() {
    this.loading = true;
    resource.onlineBooks().then(response => {
      this.books = response.data.data;
      this.loading = false;
    })
  },

  methods: {

    downloadFile(file,mime){

        resource.downloadFile(file).then(response=>{

          const fileBlob = new Blob([response.data], {type: mime})

          const fileURL = URL.createObjectURL(fileBlob);


          switch(mime){

            case 'application/pdf':

              window.open(fileURL+'#toolbar=0','_blank')

            break

            default:
        

            const downloadLink = document.createElement('a');
            downloadLink.href = fileURL;
            downloadLink.download = file.replace('online_books/','')+'.'+file.split('.').pop();
            document.body.appendChild(downloadLink);
            downloadLink.click();

            URL.revokeObjectURL(fileURL);


            }


        })

    }



  }
};
</script>
<style scoped>
@import '~vue-good-table/dist/vue-good-table.css';

td {
  min-height: 40px;
  padding-right: 4px;
  padding-left: 4px;
}
</style>
